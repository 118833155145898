import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --maxWidth: 1280px;
    --tan: #FAEBD8;
    --red: #800000;
  }
  html {
    margin: 0;
    padding: 0;
  }
  
  body {
    margin: 0;
    padding: 0;
    height: 100%;

    h1 {
      font-size: 2rem;
      font-weight: 600;
      color: black;
    }

    h3 {
      font-size: 1.1rem;
      font-weight: 600;
    }

    p {
      font-size: 1rem;
      color: black;
    }
  }
`;
