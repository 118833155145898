import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
// Firebase
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../Firebase/firebaseConfig";
// import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
// Styles
import "./styles.scss";
// Components
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import ModalComponent from "../components/ModalComponent";
// import useGetDocs from "../Hooks/useGetDocs";
// Data
// import Clothes from "../data";
// import * as url from "url";

// const query = async () => {
//   const querySnapshot = await getDocs(collection(firestore, "clothes"));
//   return querySnapshot.forEach((doc) => {
//     // doc.data() is never undefined for query doc snapshots
//     console.log(doc.id, " => ", doc.data());
//   });
// };

const HomePage = () => {
  const [isModalComponentOpen, setIsModalComponentOpen] = useState(false);
  const [modalId, setModalId] = useState("");
  const [modalBrand, setModalBrand] = useState("");
  const [modalName, setModalName] = useState("");
  const [modalColor, setModalColor] = useState("");
  const [modalPrice, setModalPrice] = useState("");
  const [modalSize, setModalSize] = useState("");
  const [modalImage, setModalImage] = useState(null);
  const [modalImage2, setModalImage2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [isActive, setIsActive] = useState(false);

  // const query = async () => {
  //   let data = [];
  //   const querySnapshot = await getDocs(collection(firestore, "clothes"));
  //   querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     // console.log(doc.id, " => ", doc.data());
  //     data.push(doc.data());
  //   });
  //
  //   setModalData(data);
  //   return data;
  // };

  const getAccounts = async () => {
    // setModalData([]);
    let data = [];
    const snapshot = await getDocs(collection(firestore, "clothes"));
    snapshot.forEach((item) => {
      // if (modalData == null) {
      //   data.push(item.data());
      //   setModalData([item.data()]);
      //   tally++;
      // } else {
      //   data.push((prevAccounts) => [...prevAccounts, item.data()]);
      // setModalData((prevAccounts) => [...prevAccounts, item.data()]);
      //   tally++;
      // }

      // data = data.concat(item.data());

      data.push({ ...item.data(), id: item.id });
      // console.log(item.data());
      // console.log("modal: ", modalData);
      // console.log("data: ", data);
    });
    setModalData(data);
    console.log("data: ", data);
    return data;
  };

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  useEffect(() => {
    // query();
    // let data = [];
    // const querySnapshot = async () => {
    //   await getDocs(collection(firestore, "clothes"));
    //   querySnapshot.forEach((doc) => {
    //     // doc.data() is never undefined for query doc snapshots
    //     // console.log(doc.id, " => ", doc.data());
    //     data.push(doc.data());
    //   });
    // };
    //
    // querySnapshot().then((r) => console.log(data));
    // setModalData(data);
    setIsLoading(true);
    // getAccounts().then(() => console.log("modalData: ", modalData));
    setTimeout(() => {
      console.log(getAccounts());
      setIsLoading(false);
    });
    // setIsLoading(true);
    // setTimeout(async () => {
    //   const querySnapshot = await getDocs(collection(firestore, "clothes"));
    //   querySnapshot.map((item) => {
    //     data = data.concat(item);
    //     return data;
    //   });
    //   // querySnapshot.forEach((doc) => {
    //   //   // doc.data() is never undefined for query doc snapshots
    //   //   // console.log(doc.id, " => ", doc.data());
    //   //   // console.log(doc.data());
    //   //   data.push(doc.id, " => ", doc.data());
    //   // });
    //
    //   data = querySnapshot;
    //   setModalData(data);
    //   console.log(modalData);
    //   setIsLoading(false);
    // }, 4000);
  }, []);

  return (
    <div className="HomePage">
      <ModalComponent
        open={isModalComponentOpen}
        close={() => {
          setModalBrand("");
          setIsModalComponentOpen(false);
        }}
        id={modalId}
        brand={modalBrand}
        color={modalColor}
        name={modalName}
        price={modalPrice}
        size={modalSize}
        image={modalImage}
        image2={modalImage2}
      />
      <Header />
      <div className="container">
        {/*{modalData.map((item) => {*/}
        {/*  return (*/}
        {/*    <div>*/}
        {/*      <h1>{item.name}</h1>*/}
        {/*    </div>*/}
        {/*  );*/}
        {/*})}*/}
        <div className="Marquee">
          <div className="Marquee-content">
            {!isLoading ? (
              modalData.map((item) => {
                if (item.name) {
                  // let imgURL = "";
                  // const pathReference = ref(storage, item.imageRef);
                  //
                  // getDownloadURL(ref(pathReference)).then((url) => {
                  //   console.log(url);
                  //   imgURL = url;
                  // });

                  return (
                    <div className="Marquee-tag">
                      <div className="card">
                        <button
                          className="modalBtn"
                          onClick={() => {
                            setIsModalComponentOpen(true);
                            setModalId(item.id);
                            setModalBrand(item.brand);
                            setModalColor(item.color);
                            setModalName(item.name);
                            setModalPrice(item.price);
                            setModalSize(item.size);
                            setModalImage(item.imageRef);
                            // setModalImage2(item.image2);
                            // setModalImage(item.imageRef);
                            setModalImage2(item.imageRef2);
                          }}
                        >
                          <img src={item.imageRef} alt={item.name} />
                        </button>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <>
                <ReactLoading type="spinningBubbles" color="black" />
                <div className="Marquee-tag">
                  <div className="card"></div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="button-container">
          <button className="btn-more" onClick={handleClick}>
            View All
          </button>
        </div>

        <div className={isActive ? "allItems active" : "allItems"}>
          {!isLoading ? (
            modalData.map((item) => {
              if (item.name) {
                return (
                  <div className="allItems-card">
                    <button
                      className="modalBtn"
                      onClick={() => {
                        setIsModalComponentOpen(true);
                        setModalId(item.id);
                        setModalBrand(item.brand);
                        setModalColor(item.color);
                        setModalName(item.name);
                        setModalPrice(item.price);
                        setModalSize(item.size);
                        setModalImage(item.imageRef);
                        // setModalImage2(item.image2);
                        // setModalImage(item.imageRef);
                        setModalImage2(item.imageRef2);
                      }}
                    >
                      <img src={item.imageRef} alt={item.name} />
                    </button>
                  </div>
                );
              }
            })
          ) : (
            <ReactLoading type="spinningBubbles" color="black" />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
