import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB5b0jGB1j5UueaJD8xYNnTp4JzETPG-TE",
  authDomain: "secnd-chance.firebaseapp.com",
  projectId: "secnd-chance",
  storageBucket: "secnd-chance.appspot.com",
  messagingSenderId: "81539627469",
  appId: "1:81539627469:web:32043d30f09ac1640f6c84",
  measurementId: "G-1MFJNEXSMH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);
