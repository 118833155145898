import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";

export const Wrapper = styled.div`
  margin: 0;
  width: 100%;
`;

export const Container = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 10px;
  display: flex;
`;

export const LogoImage = styled.img`
  width: 100%;
`;

export const NavMenu = styled.div`
  display: flex;
  //margin-left: auto;
  justify-content: space-evenly;
  flex: 1;

  @media screen and (max-width: 770px) {
    display: none;
  }
`;

export const NavLink = styled(Link)`
  color: black;
  display: flex;
  align-items: end;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    border-bottom: 1px solid black;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: var(--red);
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
