import React, { useEffect, useState } from "react";
// styles
import "./styles.scss";
// // Assets
// import IMG0837 from "../../assets/clothes-images/IMG-0837.png";
// import IMG0843 from "../../assets/clothes-images/IMG-0843.png";
// import IMG0844 from "../../assets/clothes-images/IMG-0844.png";
// import IMG0848 from "../../assets/clothes-images/IMG-0848.png";
// import IMG0855 from "../../assets/clothes-images/IMG-0855.png";
// import IMG0858 from "../../assets/clothes-images/IMG-0858.png";
// Icons
import { IoClose } from "react-icons/io5";
import { FiInstagram } from "react-icons/fi";

const ModalComponent = ({
  open,
  children,
  close,
  id,
  brand,
  color,
  name,
  price,
  size,
  image,
  image2,
}) => {
  if (!open) return null;

  return (
    <div className="modalOverlay">
      <div className="modal">
        <button onClick={close} className="btn">
          <IoClose />
        </button>

        <h1>
          {brand} {name}
        </h1>
        <div className="imgSlider">
          <img src={image} />
          {image2 && <img src={image2} />}
        </div>

        <div className="bottom">
          <div className="text">
            <div className="text-left">
              <h3>Price:</h3>
              <h3>Size:</h3>
              <h3>Color:</h3>
            </div>
            <div className="text-right">
              {price.length > 0 && <h3>${price}</h3>}
              <h3>{size}</h3>
              <h3>{color}</h3>
            </div>
          </div>
          <a href="https://instagram.com/secndchance.us">
            <FiInstagram />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
