import React from "react";
// Navigation
import { Routes, Route } from "react-router-dom";
// Components
import Home from "./components/Home";
import NavBar from "./components/NavBar";
//Styles
import { GlobalStyle } from "./GlobalStyle";
// Styles
import "./default.scss";
// Pages
import HomePage from "./Pages/HomePage";
import UploadPage from "./Pages/UploadPage";

const App = () => {
  return (
    <div className="App">
      {/*<NavBar />*/}
      {/*<Routes>*/}
      {/*  <Route path="/" element={<Home />} />*/}
      {/*</Routes>*/}
      {/*<GlobalStyle />*/}
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Routes>
        <Route path="/upload" element={<UploadPage />} />
      </Routes>
    </div>
  );
};

export default App;
