import React, { useEffect, useState } from "react";
// Styles
import "./styles.scss";
// Logo
import Logo from "../../images/Word Logo Trans copy.png";

const Header = () => {
  return (
    <div className="Header">
      <img src={Logo} alt="Logo" />
    </div>
  );
};

export default Header;
