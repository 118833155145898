import React, { useEffect, useState } from "react";
// Styles
import "./styles.scss";
// Assets
import Three14Logo from "../../assets/Three14-Logo-Light-noPadding.png";

const Footer = () => {
  return (
    <div className="footer">
      <p>Secnd Chance © </p>
      <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        <a href="https://three14.llc">
          <span style={{ color: "black", marginRight: ".6rem" }}>
            powered by{" "}
          </span>{" "}
          <a href="https://three14.llc" style={{ marginRight: ".6rem" }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/three14-31dee.appspot.com/o/Three14-Banner-Logo-White.png?alt=media&token=8b2d10c4-768e-4770-ba43-4496babf6d25"
              alt="Three14 Logo"
            />
          </a>
        </a>
        {/*<a href="https://three14.llc">*/}
        {/*  <img src={Three14Logo} alt="Three14 Logo" />*/}
        {/*</a>*/}
      </div>
    </div>
  );
};

export default Footer;
