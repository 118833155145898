import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: var(--red);
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: -20px;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
  }
  @media (max-width: 400px) {
    width: 200px;
  }
  @media screen and (min-width: 770px) {
    display: none;
  }
`;

export const NavLink = styled(Link)`
  color: white;
  display: flex;
  text-decoration: none;
  padding: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  &.active {
    border-bottom: 1px solid white;
  }
`;
