import styled from "styled-components";
import BackgroundImg from "../images/8-Best-Op-Shops-and-Vintage-Shops-in-Melbourne-Vintage-Sole-2-2.jpg";

export const Wrapper = styled.div`
  height: 100vh;
  background-image: url("${BackgroundImg}");
  background-position: center;
  background-size: cover;

  Footer {
    bottom: 0;
    background-color: red;
  }
`;

export const Container = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 10px;
`;
