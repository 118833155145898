import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
// Styles
import "./upload.styles.scss";
// Firebase
import { storage, firestore } from "../Firebase/firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";

const UploadPage = () => {
  const [clothesBrand, setClothesBrand] = useState("");
  const [clothesColor, setClothesColor] = useState("");
  const [clothesName, setClothesName] = useState("");
  const [clothesPrice, setClothesPrice] = useState("");
  const [clothesSize, setClothesSize] = useState("");
  const [clothesImage, setClothesImage] = useState(null);
  const [clothesImageRef, setClothesImageRef] = useState("");
  const [clothesImage2, setClothesImage2] = useState(null);
  const [clothesImageRef2, setClothesImageRef2] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");

  const imagesListRef = ref(storage, "images/");

  // const uploadFile = () => {
  //   if (imageUpload == null) return;
  //   const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
  //   uploadBytes(imageRef, imageUpload).then((snapshot) => {
  //     getDownloadURL(snapshot.ref).then((url) => {
  //       setImageUrls((prev) => [...prev, url]);
  //     });
  //   });
  // };

  const uploadClothes = async (event) => {
    event.preventDefault();

    const imageRefName = `images/clothes/${clothesImage.name + v4()}`;
    let imageRefName2 = "";
    const imageRef = ref(storage, imageRefName);
    let imageRef2;
    let linkUrl;

    setClothesImageRef(imageRefName);

    if (clothesImage2) {
      imageRefName2 = `images/clothes/${clothesImage2.name + v4()}`;
      imageRef2 = ref(storage, imageRefName2);
      setClothesImageRef2(imageRefName2);
    }

    const resetForm = () => {
      setClothesBrand("");
      setClothesColor("");
      setClothesName("");
      setClothesPrice("");
      setClothesSize("");
      setClothesImage(null);
      setClothesImageRef("");
      setClothesImage2(null);
      setClothesImageRef2(null);
    };

    // if (clothesImage2 !== null) {
    //   uploadBytes(imageRef2, clothesImage2).then(() => {
    //     alert("Clothes image 2 uploaded successfully.");
    //     getDownloadURL(imageRef2).then((url) => {});
    //   });
    // }

    uploadBytes(imageRef, clothesImage)
      .then((snapshot) => {
        alert("Clothes image uploaded successfully.");
        // if (clothesImage2 !== null) {
        //   uploadBytes(imageRef2, clothesImage2).then(() =>
        //     alert("Clothes image 2 uploaded successfully.")
        //   );
        // }
      })
      .then(() => {
        if (clothesImage2 !== null) {
          uploadBytes(imageRef2, clothesImage2).then(() =>
            alert("Clothes image 2 uploaded successfully.")
          );
        }
      })
      .then(() => {
        if (!imageRef2) {
          getDownloadURL(imageRef).then(async (url) => {
            linkUrl = url;
            const docRef2 = await addDoc(collection(firestore, "clothes"), {
              brand: clothesBrand,
              color: clothesColor,
              name: clothesName,
              price: clothesPrice,
              size: clothesSize,
              imageRef: url,
              imageRef2: imageRefName2,
              created: serverTimestamp(),
            }).then(() => alert("Clothes item added successfully."));
          });
        }
      })
      .then(() => {
        if (imageRef2) {
          getDownloadURL(imageRef2).then(async (url2) => {
            const docRef = await addDoc(collection(firestore, "clothes"), {
              brand: clothesBrand,
              color: clothesColor,
              name: clothesName,
              price: clothesPrice,
              size: clothesSize,
              imageRef: linkUrl,
              imageRef2: url2,
              created: serverTimestamp(),
            }).then(() => alert("Clothes item added successfully."));
          });
        }
      })
      .then(() => resetForm());

    // .then(() => {
    //   getDownloadURL(imageRef).then(async (url) => {
    //     if (imageRef2) {
    //       getDownloadURL(imageRef2).then(async (url2) => {
    //         const docRef = await addDoc(collection(firestore, "clothes"), {
    //           brand: clothesBrand,
    //           color: clothesColor,
    //           name: clothesName,
    //           price: clothesPrice,
    //           size: clothesSize,
    //           imageRef: url,
    //           imageRef2: url2,
    //           created: serverTimestamp(),
    //         }).then(() => alert("Clothes item added successfully."));
    //       });
    //     } else {
    //       const docRef2 = await addDoc(collection(firestore, "clothes"), {
    //         brand: clothesBrand,
    //         color: clothesColor,
    //         name: clothesName,
    //         price: clothesPrice,
    //         size: clothesSize,
    //         imageRef: url,
    //         imageRef2: imageRefName2,
    //         created: serverTimestamp(),
    //       }).then(() => alert("Clothes item added successfully."));
    //     }
    //   });
    // });

    // if (imageRef2) {
    //   getDownloadURL(ref(imageRef2)).then(async (url2) => {
    //     const docRef = await addDoc(collection(firestore, "clothes"), {
    //       brand: clothesBrand,
    //       color: clothesColor,
    //       name: clothesName,
    //       price: clothesPrice,
    //       size: clothesSize,
    //       imageRef: link,
    //       imageRef2: url2,
    //       created: serverTimestamp(),
    //     }).then(() => alert("Clothes item added successfully."));
    //   });
    // } else {
    //   getDownloadURL(ref(imageRef)).then(async (url) => {
    //     const docRef2 = await addDoc(collection(firestore, "clothes"), {
    //       brand: clothesBrand,
    //       color: clothesColor,
    //       name: clothesName,
    //       price: clothesPrice,
    //       size: clothesSize,
    //       imageRef: url,
    //       imageRef2: imageRefName2,
    //       created: serverTimestamp(),
    //     }).then(() => alert("Clothes item added successfully."));
    //   });
    // }

    // important
    // getDownloadURL(ref(imageRef)).then(async (url) => {
    //   let link = url;
    //   if (clothesImage2 !== null) {
    //     getDownloadURL(ref(imageRef2)).then(async (url2) => {
    //       const docRef = await addDoc(collection(firestore, "clothes"), {
    //         brand: clothesBrand,
    //         color: clothesColor,
    //         name: clothesName,
    //         price: clothesPrice,
    //         size: clothesSize,
    //         imageRef: url,
    //         imageRef2: url2,
    //         created: serverTimestamp(),
    //       }).then(() => alert("Clothes item added successfully."));
    //     });
    //   } else {
    //     const docRef2 = await addDoc(collection(firestore, "clothes"), {
    //       brand: clothesBrand,
    //       color: clothesColor,
    //       name: clothesName,
    //       price: clothesPrice,
    //       size: clothesSize,
    //       imageRef: url,
    //       imageRef2: imageRefName2,
    //       created: serverTimestamp(),
    //     }).then(() => alert("Clothes item added successfully."));
    //   }
    // });
  };
  // const uploadClothes = async (event) => {
  //   event.preventDefault();
  //
  //   const imageRefName = `images/clothes/${clothesImage.name + v4()}`;
  //   let imageRefName2 = "";
  //   const imageRef = ref(storage, imageRefName);
  //   let imageRef2;
  //
  //   setClothesImageRef(imageRefName);
  //
  //   if (clothesImage2) {
  //     imageRefName2 = `images/clothes/${clothesImage2.name + v4()}`;
  //     imageRef2 = ref(storage, imageRefName2);
  //     setClothesImageRef2(imageRefName2);
  //   }
  //
  //   const docRef = await addDoc(collection(firestore, "clothes"), {
  //     brand: clothesBrand,
  //     color: clothesColor,
  //     name: clothesName,
  //     price: clothesPrice,
  //     size: clothesSize,
  //     imageRef: imageRefName,
  //     imageRef2: imageRefName2,
  //     created: serverTimestamp(),
  //   }).then(() => alert("Clothes item added successfully."));
  //
  //   uploadBytes(imageRef, clothesImage).then((snapshot) => {
  //     alert("Clothes image uploaded successfully.");
  //     if (clothesImage2 !== null) {
  //       uploadBytes(imageRef2, clothesImage2).then(() =>
  //         alert("Clothes image 2 uploaded successfully.")
  //       );
  //     }
  //   });
  // };

  // useEffect(() => {
  //   listAll(imagesListRef).then((response) => {
  //     response.items.forEach((item) => {
  //       getDownloadURL(item).then((url) => {
  //         setImageUrls((prev) => [...prev, url]);
  //       });
  //     });
  //   });
  // }, []);

  return (
    <div className="UploadPage">
      <form onSubmit={uploadClothes}>
        <h1>Clothes Upload</h1>
        <input
          type="text"
          name="brand"
          placeholder="Brand"
          value={clothesBrand}
          onChange={(e) => setClothesBrand(e.target.value)}
        />
        <input
          type="text"
          name="color"
          placeholder="Color"
          value={clothesColor}
          onChange={(e) => setClothesColor(e.target.value)}
        />
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={clothesName}
          onChange={(e) => setClothesName(e.target.value)}
        />
        <input
          type="text"
          name="price"
          placeholder="Price"
          value={clothesPrice}
          onChange={(e) => setClothesPrice(e.target.value)}
        />
        <input
          type="text"
          name="size"
          placeholder="Size"
          value={clothesSize}
          onChange={(e) => setClothesSize(e.target.value)}
        />
        <label htmlFor="image1">Image 1</label>
        <input
          type="file"
          name="image1"
          id="image1"
          onChange={(event) => {
            setClothesImage(event.target.files[0]);
          }}
        />
        <label htmlFor="image2">Image 2</label>
        <input
          type="file"
          name="image2"
          id="image2"
          onChange={(event) => {
            setClothesImage2(event.target.files[0]);
          }}
        />

        <button type="submit">Upload</button>
      </form>

      {/*<input*/}
      {/*  type="file"*/}
      {/*  onChange={(event) => {*/}
      {/*    setImageUpload(event.target.files[0]);*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<button onClick={uploadFile}> Upload Image</button>*/}

      {/*{imageUrls.map((url) => {*/}
      {/*  return <img src={url} style={{ height: "40rem" }} />;*/}
      {/*})}*/}
    </div>
  );
};

export default UploadPage;
